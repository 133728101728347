export default {
  textColor: '#fff',
  facebookColor: '#4267B2',
  instagramColor: '#C13584',
  youtubeColor: '#FF0000',
  whatsappColor: '#4AC959',
  blogColor: '#FF585D',
  linkedinColor: '#2867B2',
  twitterColor: '#1DA1F2',
  githubColor: '#24292e',
  opt1Color: '#366251',
  opt2Color: '#FF8300',
}
